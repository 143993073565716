import React from 'react'
import {ListGroup,  ListGroupItem, Row, Col} from 'reactstrap'
import StoreContext from '../../store'
import {groupBy} from '../helpers'

export default function ElementsList ({data, label, shuffled=false, grouped=false,}) {

    const {config: {selected, field, visibilityFields, squared} }= React.useContext(StoreContext)

    const {tracce, candidati} = data

    // Grouped: Elementi raggruppati per un campo
    if(grouped){  
        const pressoGrouped = groupBy(field)
        const groups = pressoGrouped(data)
        const indexed = Object.keys(groups)
        
        return <>
            {indexed.map( (itemField,keyID) => {
                if(shuffled){
                    
                    groups[itemField].map( (item,id) => {
                        if(id < selected){
                            item.selected = true
                        } else {
                            item.selected = false
                        }
                        item.key = item.numero + "list" || id + "list"
                        
                        return item
                    } )
                }

                const titolo =  itemField !== "undefined" ? itemField : "Elenco"
                return <div key={keyID+"-m"}>
                <h1>{titolo}</h1>
                <ListGroup>
                {groups[itemField].map( (item, idx) => {
                    const bold = item.selected === true ? 'font-weight-bold' : ""
                        return <ListGroupItem key={item.key+"list"}>
                            <span className={bold}>{idx+1} - { visibilityFields.map( el => item[el.value] + " ")} {!!squared.length && <>[{item[squared]}]</>}</span>
                    </ListGroupItem>   
                })}
            </ListGroup>
            </div>
            })}
        </>
    }
        

    // Elementi non raggruppati.
    if(shuffled){
        console.log(shuffled)
        tracce.map( (item,idx) => {
            console.log(item)
            if(idx < selected){
                item.selected = true
            } else {
                item.selected = false
            }
            item.key = idx +"-ungruped"
            return item
        } )
    }

    return <>
        <h3>{label}</h3>
        <Row>
            <Col md={6}>
                <ListGroup>
                    {tracce.map((item, idx ) => <ListGroupItem key={item.key+"-list"}>
                            <span className={item.selected === true ? 'font-weight-bold' : ""}>{idx+1} - { visibilityFields.map( el => item[el.value] + " ")} {!!squared.length && <>[{item[squared]}]</>}</span>
                        </ListGroupItem>
                    )}
                </ListGroup>
            </Col>
            <Col md={6}>
                <ListGroup>
                    {candidati.map((item, idx ) => <ListGroupItem key={item.key+"-list"}>
                            <span> {item['Matricola']} - {item["Cognome"]} {item["Nome"]} </span>
                        </ListGroupItem>
                    )}
                </ListGroup>
            </Col>
        </Row>
    </>
}   