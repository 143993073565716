import React from 'react'
import { pdf, Page as PdfPage, Text, View, Document as PdfDocument, PDFViewer, StyleSheet as PdfStyleSheet, Image, Font} from '@react-pdf/renderer';
import moment from 'moment'
import StoreContext from '../../store'
import {saveAs} from 'file-saver'
import {Button} from 'reactstrap'

// Fonts importati
import OpenSans from "../../assets/OpenSans-Regular.ttf"
import OpenSansBold from "../../assets/OpenSans-Bold.ttf"

Font.register({
    family: 'OpenSans',
        format: "truetype",
        src: OpenSans
  });

Font.register( {
  family: "OpenSansBold",
  format: "truetype",
  src: OpenSansBold
})



const styles = PdfStyleSheet.create({
    page: {
        paddingTop: "20pt",
        paddingBottom: "30pt",
        paddingHorizontal: "20pt",
        backgroundColor: '#fff',
        fontFamily: 'OpenSans'
    },
    section: {
      fontSize: 12,
      paddingTop: "5pt", 
      paddingLeft: "20pt",
      paddingBottom: "20pt",
      display: 'block',
      textAlign: "center",
    },
    image: {
        width: "50pt", 
        height: "54pt",
        left: 252,
        paddingBottom: 6,
        textAlign: "center"
    },
    title: {
        textAlign: 'center',
        margin: 0,
        fontSize: 20,
        paddingBottom: "20pt"
    },
  });


  


export default function PdfCreate (props){
  const {config} = React.useContext(StoreContext) 
  const { institutionLogo, institutionName, titleSorteggio, pdfVisibilityFields, pdfSquared} = config
  
  const {data, elenco} = props

 

const savePdf = async () => {
    const blob = await pdf(<PdfTemp {...props} />).toBlob()
      saveAs(blob, `${titleSorteggio + "_" + moment().format('DD-MM-YYYY')}.pdf`)
  }

  const PdfTemp = ()=><PdfDocument>
      <PdfPage size={'A4'} wrap={true} orientation={'portrait'} style={styles.page}>
          <View fixed style={styles.title} >
            <Text style={{fontSize: 8, textAlign: "right"}} render={({ pageNumber, totalPages }) => (
              `${pageNumber} / ${totalPages}`
            )} fixed />
                <View style={{textAlign: 'center'}}>
                      <Image src={institutionLogo} style={styles.image} />
                <Text style={{fontSize: 16, fontFamily: "OpenSansBold"}}>{institutionName}</Text>
                </View>
                <View>
                  <Text style={{fontSize: 14}}>
                    {titleSorteggio}
                </Text>
                <Text style={{fontSize: 16}}>
                    Sorteggio del {moment().format('DD/MM/YYYY - HH:mm:ss')}
                </Text>
                </View>
          </View>
          <View>
            <Text style={{fontSize: 12, fontFamily: "OpenSans", textAlign: 'center'}}>{titleSorteggio}</Text>
          </View>
          <View style={{paddingBottom: "10pt",  textAlign: "left"}}>
          {elenco.map((item, idx) => {
              return (
                <View key={item} style={{ padding: 2, witdh: "100%"}} wrap={false}>
                    <Text style={{ fontFamily: "OpenSansBold", padding: "4pt", fontSize: 13 }}> [{item.Matricola}] - {item.Cognome} {item.Nome}: ({data[idx].codice}) {data[idx].testo}</Text>
                </View>
              );
            })}
          </View>
         {/**
          * 
          * <View style={{margin: 30, paddingBottom: 10}}>
                <View style={styles.section} >
                  {candidati.map( (item, idx) => {
                    const styleSingle = {paddingBottom: "5pt"}
                    if(item.selectedGroup !== ""){
                      styleSingle.fontFamily = 'OpenSansBold'
                    }
                  return <View key={idx} style={styleSingle} >
                        <Text style={{fontSize: 13}}>{idx+1} - {pdfVisibilityFields.map( element => item[element.value] + " ")} 
                                 {pdfSquared.length ? `[${item[pdfSquared]}]`: ""}</Text>
                      </View>
                    }
                  )}
                </View>
          </View>
          * 
          */}
          
    </PdfPage>
  </PdfDocument>




  return <div className="text-center">
    <Button onClick={()=>savePdf()} color="success">Salva su disco</Button>
    <br />
    <br />
    <PDFViewer width={600} height={800}>
      <PdfTemp />
    </PDFViewer>
  </div>
}
