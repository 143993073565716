
  // Default config for store context (store.js)
// Fonts import
import OpenSans from "./assets/OpenSans-Regular.ttf"
import OpenSansBold from "./assets/OpenSans-Bold.ttf"
import Logo from "./assets/logo.png"


 const defaultPdfFonts = {
    OpenSans: OpenSans,
    OpenSansBold: OpenSansBold
 }

  const appConfig = {
    appName: "Sorteggio Unime",
    institutionName: "Università degli Studi di Messina",
    institutionLogo: Logo,
    algoritmo: 2,
    seggi: 1,   
    winners: 7,
    selected: 0,
    groupField: "",
    visibilityFields: [ {value: "codice", label:"codice" }, {value: "testo", label:"testo"}],
    squared: "",
    pdfVisibilityFields: [ {value: "codice", label:"codice" }, {value: "testo", label:"testo"}],
    pdfSquared: "",
    titleSorteggio: " Non Definito (prenderà il nome dal file) "
  }

export {defaultPdfFonts}
export default appConfig;